@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("/public/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

body {
  background: #f0f0f0 !important;
}

[type='button'].ms-Button--primary {
  background-color: rgb(0, 120, 212)
}

[type='button'].ms-Button--primary:hover {
  background-color: rgb(16, 110, 190);
  border: 1px solid rgb(16, 110, 190);
  color: rgb(255, 255, 255);
}